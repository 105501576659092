import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerBG from "../images/service-bg.png"
import ServicesCarousel from "../components/servicesCarousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Banner = styled.section`
  background-image: url(${BannerBG});
  background-position: top;
  background-size: auto 100%;
  background-repeat: no-repeat;

  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  h1 {
    font-size: ${props => props.theme.fontSize.h3};
  }
  h5 {
    width: 80%;
    margin: 0.5rem auto 5vh;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: ${props => props.theme.fontSize.h1};
      margin-bottom: 1rem;
    }
    h5 {
      margin-bottom: 10vh;
    }
  }
  @media (min-width: 1024px) {
    min-height: 70vh;
    background-size: cover;
    h5 {
      width: 60%;
    }
  }
`

const ServiceDescription = styled.section`
  p {
    padding: 0 1.6rem;
  }
  .gatsby-image-wrapper {
    margin: 0 auto 1rem;
    max-width: 350px;
  }

  @media (min-width: 768px) {
    .service-description {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
  }
  @media (min-width: 1024px) {
    .service-description {
      max-width: 960px;
    }
  }
`
const ServiceIntro = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin: 2rem auto;
    .gatsby-image-wrapper {
      width: 40%;
    }
    p {
      width: 60%;
      margin-bottom: 0;
      padding-right: 0;
    }
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
`
const CallToAction = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
  padding: 2rem 0;
  margin: 2rem auto;
  width: 96%;

  h4 {
    margin-bottom: 2rem;
  }
`
const ServiceEnding = styled.div`
  p {
    padding: 0 1.6rem;
  }
  @media (min-width: 768px) {
    p {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
  }
  @media (min-width: 1024px) {
    p {
      max-width: 960px;
    }
  }
`
const MoreServices = styled.div`
  margin: 2rem 0;

  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin: 2rem auto;
    max-width: 960px;
    h3 {
      width: 50%;
    }
  }
`

const OxidativeLists = styled.div`
  h4,
  h5 {
    margin-bottom: 0.5rem;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
  h5 {
    margin: 2rem auto 1rem;
  }
  p,
  ol,
  ul {
    padding: 0 1.6rem;
  }
  li {
    margin-bottom: 0.6rem;
  }
  @media (min-width: 768px) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
`

const BIHRInfo = styled.div`
  padding: 0 1.6rem;
  @media (min-width: 768px) {
    margin: 1rem auto 0;
    width: 90%;
  }
  @media (min-width: 1024px) {
    max-width: 960px;
  }
`
const BIHRWomen = styled.div`
  background: ${props => props.theme.colors.secondary};
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 3px;
  color: white;

  hr {
    margin: 1rem 0;
  }

  @media (min-width: 768px) {
    margin-top: 1rem;
  }
  @media (min-width: 1024px) {
  }
`
const BIHREnd = styled.div`
  h6 {
    margin-bottom: 0.7rem;
  }
`

const ServiceTemplate = ({ pageContext: { service } }) => {
  const data = useStaticQuery(graphql`
    query ServiceImageQuery {
      allFile(filter: { sourceInstanceName: { eq: "services-images" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 350
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)

  const imageData = data.allFile.edges.filter(
    img => img.node.relativePath === service.img
  )

  const image = getImage(imageData[0].node)

  return (
    <Layout>
      <Seo title={service.name} />
      <Banner>
        <h1>{service.name}</h1>
        <h5>
          Quantum Functional Medicine is a center for health and rejuvenation.
        </h5>
      </Banner>

      <ServiceDescription>
        <ServiceIntro>
          <GatsbyImage image={image} alt={service.name} />
          <p>{service.intro}</p>
        </ServiceIntro>
        <p className="service-description">{service.description}</p>
      </ServiceDescription>
      <CallToAction>
        <h4>Have questions about toxins in your body?</h4>
        <Link to="/contact" className="btn">
          Contact Us
        </Link>
      </CallToAction>
      <ServiceEnding>
        <p className="service-ending">{service.ending}</p>
      </ServiceEnding>

      {service.slug === "oxidative-therapies" && (
        <OxidativeLists>
          <h4>We offer a number of different forms of oxidative therapies:</h4>
          <p>
            <strong>
              All of these therapies are intravenous based treatments.
            </strong>
          </p>
          <ol>
            <li>
              Intravenous Hi-dose Vitamin C ( over 25 grams, 25, 000 mg) and
              Hydrogen peroxide in low doses.
            </li>
            <li>
              Ultraviolet blood irradiation: this is a device used to stimulate
              the immune system and kill viruses. It requires blood being
              removed and run through a cuvette that is in a machine exposing
              the blood to ultraviolet light. This can be coupled with ozone
              treatment.
            </li>
            <li>
              Major autoheme therapy: this is adding ozone gas which dissolves
              in blood and other liquid and is then returned into the body.
            </li>
            <li>
              10 pass/ multipass: aggressive ozone treatment with up to 2 liters
              of your blood exposed to ozone, pressurized with oxygen and
              reintroduced into the body.
            </li>
          </ol>
          <p>
            All these types of treatment are designed to introduce a stress to
            the body stimulating he body into a more healing state. This treats
            chronic infections, like hepatitis, chronic lyme’s disease and other
            chronic infections.this is also a great treatment for keeping
            healthy people healthy and living with better quality of life. Even
            people with acute infections can respond quickly and get well
            sooner.
          </p>

          <h5>Additional treatment with oxidative therapies:</h5>
          <ul>
            <li>
              Minor auto-heme therapy: injecting blood mixed with ozone back
              into the body intramuscularly to initiate an immune response.
            </li>
            <li>
              Intravenous Ozonated Saline: infusing ozone saturated in saline
              solution in to the body.
            </li>
            <li>
              Prolozone: injecting joints with ozone to help restore the joint.
            </li>
          </ul>
        </OxidativeLists>
      )}

      {service.slug === "bio-identical-hormone-replacement" && (
        <BIHRInfo>
          <div className="bihr-description">
            <BIHRWomen>
              <p>
                For <strong>Post menopausal women</strong> looking for hormonal
                support, we offer a number of solutions such as topical or oral
                preparations of bio-identical hormone replacement with Biest (
                estriol and estradiol ) or plan estradiol. This is usuallly
                added to progesterone with or without testosterone.
              </p>
              <p>
                Other hormones including DHEA and pregnenolone can be prescribed
                a well.
              </p>
              <p>
                Pellet therapy, per the Biote method, is a convenient form of
                hormone replacement. Estradiol and testosterone can be used with
                oral or topical progesterone.
              </p>
              <hr />
              <p>
                For <strong>men</strong>, based on their levels of testosterone,
                estrogen and dihydrotestosterone, specific treatment can be
                prescribed from topical creams, injections or pellet
                treatment.There are several options in men under 65 years of age
                to stimulate their own testosterone production. needs.{" "}
              </p>
              <p>
                Each treatment is customized to met individual needs. Other
                hormones including DHEA and pregnenolone can be prescribed based
                on individual
              </p>
            </BIHRWomen>
          </div>
          <BIHREnd>
            <h6>A note on pellet treatment:</h6>
            <p>
              For men and women this option is available for placing highly
              compressed bioidentical hormone into a small pellet that is
              surgically inserted under the skin in the upper buttocks area.
              This is regarded as a relatively minor procedure with no down time
              and only 3 days of limited exercise in women and 7 days for men.
              These hormone can last 3-4 months with estradiol and 4-5 months
              with testosterone.{" "}
            </p>
            <p>
              Other herbal and nutritional supplements are offered to support
              proper hormone metabolism. This option can be discussed in more
              detail with a consultation.{" "}
            </p>
          </BIHREnd>
        </BIHRInfo>
      )}

      <MoreServices>
        <h3>More Services</h3>

        <ServicesCarousel service={service} />
      </MoreServices>
    </Layout>
  )
}

export default ServiceTemplate
